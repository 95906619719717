import { Button, FloatButton, Modal } from "antd";
import { FileTextOutlined  } from '@ant-design/icons';
import React, { useState } from "react";
import KVTable from "./KVTable";
import DynamicForms from "./DynamicForms";

const FloatPAHInput = ({source, propData, headersData, setInputProperties, setInputHeaders}) => {
const [isModalOpenP, setIsModalOpenP] = useState(false);
const [isModalOpenH, setIsModalOpenH] = useState(false);

  const showModalProperties = () => {
    setIsModalOpenP(true);
  };

  const handleOkProperties = (event) => {
    setInputProperties(event.kv)
    setIsModalOpenP(false);
    localStorage.setItem("properties", JSON.stringify(event.kv));
  };


  const showModalHeaders = () => {
    setIsModalOpenH(true);
  };

  const handleOkHeaders = (event) => {
    setInputHeaders(event.kv)
    setIsModalOpenH(false);
    localStorage.setItem("headers", JSON.stringify(event.kv));
  };


  return (
    <>
        <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{ insetInlineEnd: 94 }}
        icon={<FileTextOutlined />}
        className="absolute"
        >
        
        <FloatButton onClick={showModalHeaders} icon={<FileTextOutlined  />} description="H" />
        <FloatButton onClick={showModalProperties} icon={<FileTextOutlined  />} description="P" />
        </FloatButton.Group>

        <Modal width="min-content" title={source + " Properties"} open={isModalOpenP} onCancel={handleOkProperties} closeIcon={null} footer={[]}>
            <DynamicForms data={propData} onFinish={handleOkProperties}/>
        </Modal>

        <Modal width="min-content" title={source + " Headers"} open={isModalOpenH} closeIcon={null} footer={[]}>
            <DynamicForms data={headersData} onFinish={handleOkHeaders}/>
        </Modal>
    </>
  );
};

export default FloatPAHInput;

import React, { useState, useEffect, useRef } from 'react';
import CodeEditor from './components/CodeEditor';
import './Main.css';
import { Button, FloatButton } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import logo from './groovyidelogo.png'
import FloatPAH from './components/FloatPAH';
import FloatPAHInput from './components/FloatPAHInput';
import {getDefaultScript, parseStringToJSON} from './general';

const defaultGroovyCode = getDefaultScript;

const Main = () => {
  const [width1, setWidth1] = useState(33.33);
  const [width2, setWidth2] = useState(33.33);
  const [outputHeight, setOutputHeight] = useState(50); // Magasság százalékban
  const [isDragging, setIsDragging] = useState(false);
  const [dragIndex, setDragIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const [inputEditorContent, setInputEditorContent] = useState("");
  const [groovyEditorContent, setGroovyEditorContent] = useState(defaultGroovyCode);
  const [outputEditorContent, setOutputEditorContent] = useState("");
  const [outputProperties, setOutputProperties] = useState([]);
  const [outputHeaders, setOutputHeaders] = useState([]);
  const [inputProperties, setInputProperties] = useState([]);
  const [inputHeaders, setInputHeaders] = useState([]);

  const inputEditorRef = useRef(null);
  const groovyEditorRef = useRef(null);
  const outputEditorRef = useRef(null);
  const consoleEditorRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      if (dragIndex === 1 || dragIndex === 2) {
        const screenWidth = window.innerWidth;
        const deltaX = (e.clientX / screenWidth) * 100;
        if (dragIndex === 1) {
          setWidth1(deltaX);
          setWidth2(100 - deltaX - width3);
        } else if (dragIndex === 2) {
          setWidth2(deltaX - width1);
        }
      } else if (dragIndex === 3) {
        // Függőleges átméretezés az output és a console között
        const screenHeight = window.innerHeight;
        const deltaY = (e.clientY / screenHeight) * 100;
        setOutputHeight(deltaY);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setDragIndex(null);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragIndex, width1, width2, outputHeight]);

  useEffect(() => {
    const groovyStored = localStorage.getItem("groovy");
    setGroovyEditorContent(groovyStored);

    const inputStored = localStorage.getItem("xml");
    setInputEditorContent(inputStored);

    const headersStored = localStorage.getItem("headers");
    if (headersStored?.length > 0 && headersStored != "undefined") {
      const headersStoredJSON = JSON.parse(headersStored);
      if (headersStoredJSON) {
        setInputProperties(headersStoredJSON);
      }
    }

    const propertiesStored = localStorage.getItem("properties");
    if (propertiesStored?.length > 0 && propertiesStored != "undefined") {
      const propertiesStoredJSON = JSON.parse(propertiesStored);
      if (propertiesStoredJSON) {
        setInputProperties(propertiesStoredJSON);
      }
    }
  }, []);

  const handleMouseDown = (index) => {
    setIsDragging(true);
    setDragIndex(index);
  };

  const width3 = 100 - width1 - width2;

  const cleanScript = (script) => {
    script = script.replace(/getBody\(\s*String\s*\)/g, 'getBody()');
    script = script.replace(/&&/g, "&");
    return script;
  }

  const runScript = () => {
    const url = "https://talan-dev-co3pt0x2.it-cpi026-rt.cfapps.eu10-002.hana.ondemand.com/http/testscript";
    const groovyCode = groovyEditorRef.current.editor.getValue();
    const inputText = inputEditorRef.current.editor.getValue();
    console.log("DNI inputProperties ", inputProperties, " inputHeaders ", inputHeaders)
    const payload = cleanScript(groovyCode) + "-SEPARATOR-" + inputText + "-SEPARATOR-" + JSON.stringify(inputProperties) + "-SEPARATOR-" + JSON.stringify(inputHeaders);

    setIsLoading(true);

    axios
      .post(url, payload)
      .then((response) => {
      
        outputEditorRef.current.editor.setValue(response.data.bodyOutput, 1);
        if (response.data.consoleOutput) {
          consoleEditorRef.current.editor.setValue(response.data.consoleOutput, 1);
        }else {
          if (typeof response.data === "string") {
            consoleEditorRef.current.editor.setValue(response.data);
          }else {
            consoleEditorRef.current.editor.setValue("");
          }
        }

        if (response.data.prop) {
          const propsJSON = parseStringToJSON(response.data.prop)
          setOutputProperties(propsJSON);
        }else {
          setOutputProperties([]);
        }

        if (response.data.headers) {
          setOutputHeaders(response.data.headers);
        }else {
          setOutputHeaders([]);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        outputEditorRef.current.editor.setValue(error.message, 1);
        setIsLoading(false);
      });
  };

  return (
    <div className="main">
      <div className="panel" style={{ width: `${width1}%` }}>
        <CodeEditor language="xml" ref={inputEditorRef} value={inputEditorContent} setContentValue={setInputEditorContent} />
        <FloatPAHInput source={"Input"} propData={inputProperties} headersData={inputHeaders} setInputProperties={setInputProperties} setInputHeaders={setInputHeaders}/>
        </div>
      <div className="resizer" onMouseDown={() => handleMouseDown(1)} />
      
      <div className="panel" style={{ width: `${width2}%` }}>
        <CodeEditor language="groovy" value={groovyEditorContent} ref={groovyEditorRef} setContentValue={setGroovyEditorContent} />
        <Button size="large" shape="round" loading={isLoading} type="primary" onClick={runScript} className="absolute bottom-left" icon={<PlayCircleOutlined />} iconPosition="end">
          Run
        </Button>
      </div>
      <div className="resizer" onMouseDown={() => handleMouseDown(2)} />
      
      <div className="panel paneloutput" style={{ width: `${width3}%` }}>
        <div className="output-container">
          <div className="output-section" style={{ height: `${outputHeight}%` }}>
            <CodeEditor language="text" readOnly={true} ref={outputEditorRef} value={outputEditorContent} setContentValue={setOutputEditorContent} />
          </div>
          <div className="vertical-resizer" onMouseDown={() => handleMouseDown(3)} />
          <div className="console-section" style={{ height: `${100 - outputHeight}%` }}>
            <CodeEditor theme="terminal" language="text" readOnly={true} ref={consoleEditorRef} showLineNumber={false} />
            <FloatPAH source={"Output"} propData={outputProperties} headersData={outputHeaders}/>
          </div>
        </div>
      </div>
      
      
      
    {/* {<img src={logo} className='logo'/>} */}
    </div>
  );
};

export default Main;

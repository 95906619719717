import { Button, FloatButton, Modal } from "antd";
import { FileTextOutlined  } from '@ant-design/icons';
import React, { useState } from "react";
import KVTable from "./KVTable";

const FloatPAH = ({source, propData, headersData}) => {
const [isModalOpenP, setIsModalOpenP] = useState(false);
const [isModalOpenH, setIsModalOpenH] = useState(false);

  const showModalProperties = () => {
    setIsModalOpenP(true);
  };

  const handleOkProperties = () => {
    setIsModalOpenP(false);
  };


  const showModalHeaders = () => {
    setIsModalOpenH(true);
  };

  const handleOkHeaders = () => {
    setIsModalOpenH(false);
  };




  return (
    <>
        <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{ insetInlineEnd: 94 }}
        icon={<FileTextOutlined />}
        className="absolute"
        >
        
        <FloatButton onClick={showModalHeaders} icon={<FileTextOutlined  />} description="H" />
        <FloatButton onClick={showModalProperties} icon={<FileTextOutlined  />} description="P" />
        </FloatButton.Group>

        <Modal width="min-content" title={source + " Properties"} open={isModalOpenP} onCancel={handleOkProperties} footer={[
          <Button onClick={handleOkProperties}>
            OK
          </Button>
        ]}>
            <KVTable data={propData}/>
        </Modal>

        <Modal width="min-content" title={source + " Headers"} open={isModalOpenH} onCancel={handleOkHeaders} footer={[
          <Button onClick={handleOkHeaders}>
            OK
          </Button>
        ]}>
            <KVTable data={headersData}/>
        </Modal>
    </>
  );
};

export default FloatPAH;

import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';

// Importáljuk a szükséges módokat és a monokai témát
import 'ace-builds/src-noconflict/mode-groovy';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-monokai';

// Nyelv módok meghatározása
const languageModes = {
  groovy: 'groovy',
  text: 'text',
  xml: 'xml'
};

const CodeEditor = ({ value, onChange, language = 'groovy', width = "100%", theme = "monokai", showLineNumber = true, readOnly = false, setContentValue }, ref) => {
  const handleChange = (newValue) => {
    if (setContentValue) setContentValue(newValue);
    localStorage.setItem(language, newValue);
  };

  return (
    <AceEditor
      ref={ref}
      mode={languageModes[language] || 'text'}
      theme={theme}
      name="multi-language-editor"
      editorProps={{ $blockScrolling: true }}
      value={value}
      onChange={handleChange}
      width={width} // Szélesség beállítása prop alapján
      height="100%" // Magasság beállítása teljes panelra
      readOnly={readOnly} // Csak olvasható beállítás
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showGutter: true,
        showLineNumbers: showLineNumber,
        highlightActiveLine: true,
      }}
    />
  );
};

export default React.forwardRef(CodeEditor);

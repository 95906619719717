export function parseStringToJSON(input) {
    const jsonObject = {};
    input = input.slice(1, -1);
    const keyValuePairs = input.match(/(?:[^,"]|"(?:\\.|[^"])*")+/g);

    keyValuePairs.forEach(pair => {
        const [key, value] = pair.split(':').map(item => item.trim());
        jsonObject[key] = value;
    });

    return jsonObject;
}


export function convertToKeyValueArray(data) {
    return Object.entries(data).map(([key, value]) => ({ key, value }));
}

export function getDefaultScript() {
    return `import com.sap.gateway.ip.core.customdev.util.Message;
import java.util.HashMap;

def Message processData(Message message) {
println "You can print and see the result in the console!"
//Body 
def body = message.getBody();
message.setBody(body + "Body is modified");
//Headers 
def map = message.getHeaders();
def value = map.get("oldHeader");
println "oldHeader value: " +value
message.setHeader("oldHeader", value + "modified");
message.setHeader("newHeader", "newHeader");
//Properties 
map = message.getProperties();
value = map.get("oldProperty");
message.setProperty("oldProperty", value + "modified");
message.setProperty("newProperty", "newProperty");
return message;
}`
}
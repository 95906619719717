import { Button, FloatButton, Modal, Table } from "antd";
import {convertToKeyValueArray} from '../general';
import React from "react";

const KVTable = ({data}) => {

    const columns = [
        {
          title: 'Name',
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
        },
    ];

    data = convertToKeyValueArray(data);
  return (
    <>
        <Table columns={columns} dataSource={data} />
    </>
  );
};

export default KVTable;